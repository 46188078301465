import App from "./App.svelte";
import * as Sentry from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";

const app = new App({
  target: document.body,
});

Sentry.init({
  dsn: "https://f9ef095cda234015bf435ef68540b1ee@sentry.wuffs.dev/3",
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});



console.log(`%c FTB Debug %c Version 1.0.0-${BUILD_ID}`,  'font-weight: bold; color: #a3be8c; background: #3b4252; padding: 5px;', 'color: #88c0d0; background: #2e3440 ; padding: 5px;');

export default app;
