<script>
  import Anser from 'anser'
  import { onMount } from "svelte";

  const historyHelp = {
    fetch() {
      const history = window.localStorage.getItem("history");
      if (history) {
        return new Set(JSON.parse(history));
      }

      return new Set();
    },
    add(item) {
      const history = this.fetch();
      history.add(item);
      window.localStorage.setItem("history", JSON.stringify([...history]));
      return history;
    },
    clear() {
      window.localStorage.removeItem("history");
    },
  };

  const inDev = process.env.NODE_ENV !== "production";

  const regexp = /(FTB-DBG)([A-Z]+)/;
  const codeReg = /\?code=(FTB-DBG[a-zA-Z-]+)/;
  const linkRegexp =
    /((http|https):\/\/)([a-zA-Z0-9]+.[a-zA-Z0-9]+)\/([a-zA-Z0-9]+)/gm;

  const logAndLinkRegex =
    /([a-zA-Z-0-9._\-/]+\.(log|json|txt)): ((http|https:\/\/)[a-zA-Z0-9]+.[a-zA-Z0-9]+\/[a-zA-Z0-9]+)/gm;

  let tab = 0;
  let tabContents = [];
  let tabs = [
    {
      link: "null",
      display: "Main Console",
    },
  ];

  let validCode = false;
  let debugCode = "";
  let userInput = "";
  
  // Mobile
  let linksShown = false;
  let tabsShown = false;

  let formattedPaste = "";
  let foundLinks = [];
  let instanceWithName = new Map();

  let history = historyHelp.fetch();
  let errors = [];

  let timer;
  let placeholder = "Waiting for debug code";
  let placeholderLength = placeholder.length;
  let placeholderOriginal = placeholder;

  onMount(() => {
    if (
      window.location.search !== "" &&
      window.location.search.includes("?code=")
    ) {
      const match = codeReg.exec(window.location.search);
      if (match.length > 1 && match[1]) {
        parseCode(match[1]);
        userInput = match[1];
      }
    }
  });

  const cleanConsole = () => {
    validCode = false;
    linksShown = false;
    tabsShown = false;
    formattedPaste = "";
    debugCode = "";
    errors = [];
    instanceWithName = new Map();
    tabs = [
      {
        link: null,
        display: "Main Console",
      },
    ];
    tabContents = [];
    tab = 0;
  };

  const inputUpdate = (event) => {
    if (event.key !== "Enter" && event.keyCode !== 13) {
      return;
    }

    event.preventDefault();
    const input = userInput;
    userInput = "";
    if (input === "clearhistory") {
      cleanConsole();
      historyHelp.clear();
      history = [];
      formattedPaste = "History Cleared!";
      window.history.pushState({}, "", "/");
      return;
    } else if (input === "help") {
      cleanConsole();
      formattedPaste =
        "You can use:\n[help] - to display this message\n[clearhistory] - to clear your history";
      window.history.pushState({}, "", "/");
      return;
    }

    debounce(input);
  };

  const debounce = (v) => {
    if (v === "") {
      cleanConsole();
      window.history.pushState({}, "", "/");
      return;
    }

    clearTimeout(timer);
    timer = setTimeout(() => {
      if (v.length > 0) {
        // loading = true;
        parseCode(v);
      }
    }, 500);
  };

  const parseCode = async (code) => {
    cleanConsole();
    const match = code.match(regexp);
    if (match != null) {
      debugCode = match[2].toLowerCase();
      fetchPaste(debugCode);
    } else {
      validCode = false;
      errors.push("Not a valid code");
    }
  };

  const fetchPaste = async (code) => {
    const res = await request(code);

    if (res.status === 200) {
      cleanConsole();

      validCode = true;

      const rawMainPaste = await res.text();
      foundLinks = rawMainPaste.match(linkRegexp);

      // Populate tabs
      const uuidReg = /([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/gi;

      const logsAndLinks = rawMainPaste.match(logAndLinkRegex);
      if (logsAndLinks !== null) {
        logsAndLinks.forEach((e) => {
          const [file, link] = e.split(": ");
          if (link.includes("pste.ch")) {
            // tabs.push({ link, display: file.replace("/", " ").trim() });
            const uuid = uuidReg.exec(file);
            tabs.push({ link, display: file.trim(), uuid: uuid ? uuid[1] : null });
          }
        });
        tabs = tabs;
      }
      
      const foundPackReg = /found instance:\s*([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/gmi;
      const nameRegEx = /Name:\s*([^\u001b]*)/gmi;
      // Discover pack names
      let lines = rawMainPaste.split('\n');
      lines.forEach((e, index) => {
        const packId = foundPackReg.exec(e);
        const packName = lines[index + 1] ? nameRegEx.exec(lines[index + 1]) : null;
        if (packId != null && packName != null) {
          instanceWithName.set(packId[1], packName[1]);
        }
      })

      formattedPaste = Anser.ansiToHtml(rawMainPaste);

      const userFormattedCode = `FTB-DBG${code.toUpperCase()}`;

      window.history.pushState({ code }, "", `?code=${userFormattedCode}`);

      history = historyHelp.add(userFormattedCode);
      errors = [];
    } else {
      validCode = false;
      formattedPaste = "";
      foundLinks = [];
      errors.push(`[${code.toUpperCase()}] is not a valid code`);
      errors = errors;
    }
  };

  const openTab = async (index) => {
    linksShown = false;
    tabsShown = false;
    if (tab === index) {
      return;
    }

    tab = index;
    if (index === 0 || tabContents[index]) {
      return;
    }

    const parts = tabs[index].link.split("/");
    const code = parts[parts.length - 1];
    tabContents[index] = "Loading...";

    try {
      const res = await request(code);
      tabContents[index] = await res.text();
    } catch {
      tabContents[index] = "Failed to fetch data...";
    }
  };

  const request = async (code) => {
    const res = await fetch(
      inDev
        ? `http://wuffs.gaz492.uk:8030/https://pste.ch/raw/${code}`
        : `https://pste.ch/raw/${code}`,
      {
        mode: "cors",
      }
    );

    return res;
  };

  // fixes pasting. Only ever pastes pain text
  const onPaste = (event) => {
    event.stopPropagation();
    event.preventDefault();

    // Get pasted data via clipboard API
    const clipboardData = event.clipboardData || window.clipboardData;
    const pastedData = clipboardData.getData("Text");

    userInput = pastedData;
  };

  setInterval(() => {
    if (validCode) {
      return;
    }

    if (placeholder.length > placeholderLength + 3) {
      placeholder = placeholderOriginal;
      return;
    }
    placeholder = placeholder + ".";
  }, 500);
</script>

<div class="layout">
  <aside class="{linksShown ? 'active' : ''}">
    <div class="close-btn" on:click={() => linksShown = false}>
      <div class="icon">
        <svg xmlns="http://www.w3.org/2000/svg" width="30" viewBox="0 0 320 512"><path d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"/></svg>
      </div>
    </div>
    <div class="history area">
      <div class="heading">History</div>
      <div class="items">
        {#if history.size}
          {#each [...history] as item}
            <div
              class="item"
              on:click={() => {
                parseCode(item);
                userInput = item;
              }}
            >
              {item}
            </div>
          {/each}
        {:else}
          <div class="item-blank">No history items found...</div>
        {/if}
      </div>
    </div>

    <div class="link area">
      <div class="heading">Links</div>
      {#if validCode}
        {#if (foundLinks && foundLinks.length > 0)}
          <div class="items">
            {#each foundLinks as link}
              <a class="item" href={link} target="new">{link}</a>
            {/each}
          </div>
        {/if}
      {:else}
        <div class="item-blank">No links found...</div>
      {/if}
    </div>
  </aside>

  <main>
    <div class="action-bar">
      <p>Actions</p>
      <div class="icons">
        <div class="icon" on:click={() => linksShown = true}>
          <svg xmlns="http://www.w3.org/2000/svg" width="30" viewBox="0 0 640 512"><path d="M160 224H480V169.3C451.7 156.1 432 128.8 432 96C432 51.82 467.8 16 512 16C556.2 16 592 51.82 592 96C592 128.8 572.3 156.1 544 169.3V224H608C625.7 224 640 238.3 640 256C640 273.7 625.7 288 608 288H352V342.7C380.3 355 400 383.2 400 416C400 460.2 364.2 496 320 496C275.8 496 240 460.2 240 416C240 383.2 259.7 355 288 342.7V288H32C14.33 288 0 273.7 0 256C0 238.3 14.33 224 32 224H96V169.3C67.75 156.1 48 128.8 48 96C48 51.82 83.82 16 128 16C172.2 16 208 51.82 208 96C208 128.8 188.3 156.1 160 169.3V224zM128 120C141.3 120 152 109.3 152 96C152 82.75 141.3 72 128 72C114.7 72 104 82.75 104 96C104 109.3 114.7 120 128 120zM512 72C498.7 72 488 82.75 488 96C488 109.3 498.7 120 512 120C525.3 120 536 109.3 536 96C536 82.75 525.3 72 512 72zM320 440C333.3 440 344 429.3 344 416C344 402.7 333.3 392 320 392C306.7 392 296 402.7 296 416C296 429.3 306.7 440 320 440z"/></svg>
        </div>
        <div class="icon" on:click={() => tabsShown = true}>
          <svg xmlns="http://www.w3.org/2000/svg" width="30" viewBox="0 0 512 512"><path d="M0 96C0 78.33 14.33 64 32 64H416C433.7 64 448 78.33 448 96C448 113.7 433.7 128 416 128H32C14.33 128 0 113.7 0 96zM64 256C64 238.3 78.33 224 96 224H480C497.7 224 512 238.3 512 256C512 273.7 497.7 288 480 288H96C78.33 288 64 273.7 64 256zM416 448H32C14.33 448 0 433.7 0 416C0 398.3 14.33 384 32 384H416C433.7 384 448 398.3 448 416C448 433.7 433.7 448 416 448z"/></svg>
        </div>
      </div>
    </div>
    <div class="tabbed-area">
      <div class="tabs {tabsShown ? 'active' : ''}">
        <div class="close-btn" on:click={() => tabsShown = false}>
          <div class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="30" viewBox="0 0 320 512"><path d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"/></svg>
          </div>
        </div>
        {#each tabs as item, index}
          <div
            class="tab {tab === index ? 'active' : ''}"
            on:click={() => openTab(index)}
          >
            {((item.uuid && instanceWithName.get(item.uuid)) ? item.display.replace(item.uuid, instanceWithName.get(item.uuid)) : item.display).replace('/logs/', '/')}
          </div>
        {/each}
      </div>

      {#if tab === 0}
        <div class="tab-contents main">
          <div class="contents">
            <div class="console-area">
              <pre
                class="output">
                {#if formattedPaste}
                <pre>{@html formattedPaste}</pre>
              {:else}
                <pre class="loading">{placeholder}</pre>
                {/if}
              </pre>
              {#each errors as error}
                <pre class="pt-2">{error}</pre>
              {/each}
            </div>
          </div>
        </div>
      {:else}
        <div class="tab-contents">
          <div class="contents">
            <pre class="area">{tabContents[tab]}</pre>
          </div>
        </div>
      {/if}

      <div class="input-line">
        <span class="flashes">$</span>
        <!-- svelte-ignore a11y-autofocus -->
        <span
          class="input"
          contenteditable="true"
          tabindex="-1"
          on:keydown={inputUpdate}
          bind:innerHTML={userInput}
          on:paste={onPaste}
          autofocus
        />
      </div>
    </div>
  </main>
</div>

<style lang="scss" global>
  @import url('https://fonts.googleapis.com/css2?family=Space+Mono&display=swap');
  
  .layout {
    width: 100%;
    height: 100%;
    
    ::selection {
      background: rgba(#eceff4, 0.2);
    }

    @media (min-width: 860px) {
      display: grid;
      grid-template-columns: minmax(250px, 1fr) 5fr;

    }
    
    .close-btn {
      display: none;
      @media (max-width: 860px) {
        display: block;
        position: absolute;
        top: 1rem;
        right: 1rem;

        .icon {
          cursor: pointer;
          margin-left: 1rem;
          background-color: rgba(black, .1);
          padding: .8rem 1.2rem;
          border-radius: 5px;
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            fill: white;
            width: 20px;
          }
        }
      }
    }

    aside {
      padding: 2rem 0;
      background-color: #3b4252;
      height: 100%;
      max-height: 100%;
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      
      @media (max-width: 860px) {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
        display: none;
        background-color: #2e3440;
        
        &.active {
          display: block;
        }
      } 

      .history {
        flex: 1 1 auto;
        min-height: 0px;

        display: flex;
        flex-direction: column;
        padding-bottom: 2rem;
        align-items: flex-start;

        .heading {
          margin: 0 2rem;
        }

        .items {
          height: 100%;
          overflow-y: auto;
          width: 100%;

          .item,
          .item-blank {
            padding: 0 2rem;
          }
        }
      }

      .link {
        padding: 0 2rem 0 2rem;
      }

      .area {
        font-size: 0.875rem;

        .heading {
          background-color: #88c0d0;
          color: #3b4252;
          display: inline-block;
          padding: 0.2rem 0.4rem;
          text-transform: uppercase;
          font-weight: bold;
        }

        .item,
        .item-blank {
          margin-top: 1rem;
          display: block;
          color: #81a1c1;
          text-decoration: none;

          &:not(.item-blank) {
            cursor: pointer;
            &:hover {
              color: #a3be8c;
              text-decoration: underline;
            }
          }
        }
      }
    }

    main {
      height: 100%;
      overflow: hidden;

      @media (max-width: 860px) {
        padding-top: 5rem;
      }

      .action-bar {
        display: none;
        @media (max-width: 860px) {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: .5rem 1rem;
          background-color: rgba(black, .1);

          .icons {
            display: flex;
            .icon {
              cursor: pointer;
              margin-left: 1rem;
              background-color: rgba(black, .1);
              padding: .8rem 1.2rem;
              border-radius: 5px;
              display: flex;
              align-items: center;
              justify-content: center;

              svg {
                fill: white;
                width: 20px;
              }
            }
          }
        }
      }
      
      .tabbed-area {
        display: flex;
        flex-direction: column;
        height: 100%;

        .tabs {
          display: flex;
          flex-wrap: wrap;
          font-size: 0.75rem;
          border-bottom: 1px solid #3b4252;

          @media (max-width: 860px) {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 10;
            background-color: #2e3440;
            overflow-y: auto;
            display: none;

            &.active {
              display: block;
            }
          }

          .tab {
            padding: 0.8rem 1rem;
            opacity: 0.7;
            cursor: pointer;
            user-select: none;

            &.active {
              background: #88c0d0;
              color: #3b4252;
              opacity: 1;
              cursor: pointer;
            }

            &:not(.active):hover {
              background: #3b4252;
              opacity: 1;
            }
          }
        }

        .tab-contents {
          flex: 1 1 auto;
          overflow-y: auto;
          min-height: 0px;
          display: flex;

          &.main {
            flex-direction: column-reverse;
          }

          &:not(.main) {
            font-size: 0.8rem;
          }
        }
      }

      .area {
        padding: 1rem 2rem 2rem;
        @media (max-width: 860px) {
          padding: 1rem;
        }
      }

      .console-area {
        padding: 1rem 2rem;
        color: white;
        font-family: "Space Mono", monospace;
        line-height: 1.2rem;
        font-size: 0.875rem;
        pre {
          background: #2e3440 !important;
          font-family: "Space Mono", monospace;
          color: white;
          line-height: 1.2rem;
          font-size: 0.875rem;
        }

        .loading {
          color: white;
        }
      }

      .input-line {
        border-top: 1px solid #3b4251;
        display: flex;
        padding: 0 1rem;

        .flashes {
          margin: 0 1rem;
          padding: 1rem 0;
          color: #bf616a;
        }

        .input {
          padding: 1rem 0;
          flex: 1;
          outline: none;
        }
      }
    }
  }
</style>
